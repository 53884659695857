.c-logo {
  img {
    max-height: 57px;
    max-width: 153px;
  }
}

@include media-breakpoint-down(md) {
  .c-logo {
    text-align: center;
  }
}
