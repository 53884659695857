.c-tag {
  padding: 9px;
  padding-bottom: 0;

  &__item {
    background: $primary;
    color: $white;
    margin-right: 0.5rem;
    display: inline-block;
    padding: 0 0 0 0.5rem;
    border-radius: 4px;
    margin-bottom: 9px;
  }

  &__input {
    border: 0;
    outline: none;
    margin-bottom: 9px;
  }

  &__item,
  &__input {
    height: 30px;
  }

  &__remove {
    appearance: none;
    font-style: normal;
    cursor: pointer;
    background: none;
    height: 30px;
    line-height: 30px;
    border: 0;
    color: $white;
    font-weight: bold;
    outline: none;
  }
}

.Select-value-label {
  text-transform: lowercase;
}
