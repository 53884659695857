.c-modal {
  position: fixed;
  z-index: $zindex-modal;

  &,
  &__backdrop {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__backdrop {
    position: absolute;
    background: $headings-color;
    opacity: 0.9;
    z-index: $zindex-modal-backdrop;
    appearance: none;
    width: 100%;
    border: 0;
  }

  &__content {
    position: absolute;
    z-index: $zindex-modal + 10;

    width: 660px;
    max-width: 90vw;
    max-height: 90vh;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background: $body-bg;
    border-radius: $border-radius;

    overflow-y: scroll;
  }

  .carousel {
    img {
      max-height: 90vh;
    }
  }
}
