.c-profile--nav {
  .c-profile--statistics {
    &-item {
      min-width: 60px;
      text-align: center;
      cursor: pointer;
    }
  }
}

@include media-breakpoint-down(md) {
  .c-profile--nav {
    .c-profile--statistics {
      &-item {
        margin-right: auto;
      }
    }
  }
}
