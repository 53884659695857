.c-banner {
  min-height: 460px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  &__search {
    max-width: 700px;
  }

  &__input-area {
    background: $white;
    position: relative;
    height: 40px;

    i.icon.icon-magnify {
      position: absolute;
      left: 0;
      top: 0;
      width: 40px;
      height: 40px;

      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: -1px;
      }
    }

    input {
      padding: 0;
      min-width: 100%;
      min-height: 100%;
      padding-left: 40px;
      background: none;
      outline: none;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background: $black;
    opacity: 0.5;
  }
}
