.font {
  &-size {
    &-none {
      font-size: 0;
    }

    @each $key in map-keys($font-sizes) {
      $value: map-get($font-sizes, $key);

      &-#{$key} {
        font-size: calc(1em #{$value});
      }
    }
  }

  &-underline {
    text-decoration: underline;
  }
}
