_:-ms-fullscreen,
:root .ie11_d-banner-input {
  position: absolute;
  top: -2px;
}

_:-ms-fullscreen,
:root .ie-11_small-avatar-img {
  margin-bottom: 4px;
}

.ie11 {
  .d-flex {
    display: block !important;
  }

  .display-flex {
    display: flex !important;
  }

  .number-view-bg {
    background-color: $primary;
  }

  .d-banner {
    padding-top: 90px;
  }

  .card-height {
    overflow: hidden;
    max-height: 90px;
    margin-bottom: 40px;
  }

  .card-bt-0 {
    left: 20px;
    right: 20px;
    bottom: 20px;
    position: absolute;
  }

  .mt-7rem {
    margin-top: 7rem;
  }

  .mt-11rem {
    margin-top: 11rem;
  }

  .c-height {
    overflow: hidden;
  }

  .c-height-180 {
    height: 180px;
    overflow: hidden;
  }

  .c-height-412 {
    overflow: hidden;
  }

  .bg-none {
    background: none !important;
  }
}

.mt-home-7rem {
  margin-top: 7rem;
}

.mt-explore-4rem {
  margin-top: 4rem;
}

.tweet {
  float: left !important;
}

.c-main {
  width: 100%;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  padding-bottom: 109px;
}

.t-right {
  text-align: right !important;
}

.ie-mb-0 {
  margin-bottom: 0 !important;
}

a:focus {
  outline: none !important;
}

img:focus {
  outline: none !important;
}

@include media-breakpoint-down(sm) {
  .c-main {
    padding-bottom: 0;
  }

  .card-height {
    height: auto;
  }

  .t-right {
    text-align: initial !important;
  }

  .m-r-5 {
    margin-right: 5px;
  }
}

.coreSpriteRightChevron {
  opacity: 1 !important;
}

.coreSpriteLeftChevron {
  opacity: 1 !important;
}

@media print {
  .c-main {
    padding-bottom: 0;
  }
}

.w-72 {
  max-width: 72px;
  max-height: 72px;
}

.user-notifications {
  color: $primary;
  border: 1px solid;
  position: absolute;
  font-size: 15px;
  background: $alabaster;
  margin-top: -5px;
  font-weight: 600;
  margin-left: -10px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  padding-left: 1px;
  display: inline-block;
}

.pagination {
  display: flex;
  min-width: 280px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 5px;
    margin: 2px;
    border: 1px solid $chatelle;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    font-size: 1.125rem;
    line-height: 1.5;
    border-radius: 0.125rem;
    cursor: pointer;
    width: 45px;
    min-width: 37px;
    color: $black;

    &:nth-child(1) {
      width: 50px;
      max-width: 50px;
      flex-grow: 1;
    }

    &:nth-child(2) {
      max-width: 80px;
      width: 80px;
      flex-grow: 1;
    }

    &:nth-last-child(1) {
      width: 50px;
      max-width: 50px;
      flex-grow: 1;
    }

    &:nth-last-child(2) {
      max-width: 80px;
      width: 80px;
      flex-grow: 1;
    }

    &.disabled {
      color: $gray-900;
      background-color: $iron;
      border-color: $iron;
    }

    &.active {
      background-color: $primary;
      border-color: $primary;

      a {
        color: $white !important;
      }
    }
  }
}
