.c-checkbox {
  position: relative;
  user-select: none;

  &-label {
    margin: 0;
  }

  &-input {
    appearance: none;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $custom-checkbox-bg;
    width: 100%;
    outline: none;
    border: 0;

    &:checked {
      background: $primary;
    }
  }

  &-input,
  &-content {
    transition: $transition-base;
  }

  &-input:checked + &-content {
    color: $white;
  }

  &-content {
    position: relative;
    z-index: 1;
    color: $custom-checkbox-color;
    font-family: $headings-font-family;
  }
}

.form-check {
  padding-left: 0;
}
