.c-upper {
  position: fixed;
  right: 40px;
  bottom: 40px;
  background: $body-bg;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 10px;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity 0.4s;
  z-index: 10;

  &--active {
    opacity: 1;
    pointer-events: all;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 40%;
    background: $primary;
    border-radius: 2px;

    top: 35%;
    left: 50%;
    transform-origin: center top;
  }

  &::before {
    transform: translateX(-50%) rotate(45deg);
    margin-left: 1px;
  }

  &::after {
    transform: translateX(-50%) rotate(-45deg);
    margin-left: -1px;
  }
}

@include media-breakpoint-down(xs) {
  .c-upper {
    right: 20px;
    bottom: 20px;
  }
}
