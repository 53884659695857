.fade-enter {
  opacity: 0.01;

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
  }
}

.fade-exit {
  opacity: 1;

  &.fade-exit-active {
    opacity: 0.01;
    transition: opacity 800ms ease-in;
  }
}
