.slick {
  &-prev,
  &-next {
    z-index: 1;
  }

  &-prev {
    left: -60px;
  }

  &-next {
    right: -18px;
  }

  &-arrow {
    i {
      font-size: 24px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .slick {
    &-prev {
      left: 30px;
    }

    &-next {
      right: 30px;
    }

    &-slide {
      padding: 0 36px;
    }
  }
}

@include media-breakpoint-down(md) {
  .slick {
    &-prev {
      left: -50px;
    }

    &-next {
      right: 10px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .slick {
    &-slide {
      padding: 0 80px;
    }

    &-prev {
      left: -45px;
    }

    &-next {
      right: -10px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .slick {
    &-slide {
      padding: 0 32px;
    }
  }
}

@media (max-width: 320px) {
  .slick {
    &-slide {
      padding: 0 80px;
    }
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .slick {
    &-slider {
      margin-left: -10px;
    }

    &-prev {
      left: -40px;
    }

    &-next {
      right: -10px;
    }
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  .slick {
    &-slide {
      padding: 0 20px;
    }

    &-slider {
      margin-left: -10px;
    }

    &-prev {
      left: -40px;
    }

    &-next {
      right: -10px;
    }
  }
}
