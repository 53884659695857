.c-editor-tooltip {
  position: fixed;
  left: 50%;
  top: 50%;

  background: $black;
  color: $white;
  border-radius: $border-radius;
  transform: translate(-50%, calc(-100% - 6px));
  margin-bottom: 10px;
  z-index: 3;

  &::after {
    content: "";
    border: 5px solid transparent;
    border-top-color: $black;

    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
}
