.c-list {
  &__article {
    width: 100%;
    position: relative;
    word-wrap: break-word;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;

      background: $border-color;

      width: 40px;
      height: 1px;
    }

    &:last-child::after {
      content: none;
    }
  }
}
