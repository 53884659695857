@each $key in map-keys($colors) {
  $value: map-get($colors, $key);

  .color-#{$key} {
    color: $value;
  }

  .bg-color-#{$key} {
    background-color: $value;
  }
}
