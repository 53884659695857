.c-card {
  $card: &;
  background: $alabaster;
  z-index: 1;
  overflow: hidden;
  border-radius: $border-radius;
  position: relative;

  &-link {
    text-decoration: none;

    a:hover {
      text-decoration: none;
    }

    &-hover {
      text-decoration: none;
    }
  }

  &__width {
    max-height: 125px;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    overflow: hidden;
    z-index: -1;
    border-radius: $border-radius;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $black;
      opacity: 0.5;
      z-index: 1;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  &--wide {
    #{$card} {
      &__image {
        position: relative;
        height: 100%;
        border-radius: $border-radius;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: inset 0 0 0 1px $border-color;
      }

      &__image::before {
        content: none;
      }

      &__content {
        border: 1px solid $border-color;
        border-left-width: 0;
        border-radius: $border-radius;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__title-ie {
    word-break: break-all;
  }

  &__social {
    &--facebook {
      width: 160px;
      height: 160px;
    }
  }
}

.number-view {
  padding: 3px;
  border-radius: 3px;
}

.number-view-bg {
  background-color: rgba($primary, 0.75);

  &:hover  {
    background-color: $primary;
  }
}

.pad {
  &-top {
    &-2 {
      padding-top: 1rem;
    }
  }

  &-bot {
    &-2 {
      padding-bottom: 0;
    }
  }
}

.mar {
  &-top {
    &-2 {
      padding-top: 2rem;
    }

    &-3 {
      padding-top: 3rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .c-card {
    $card: &;

    &--wide {
      #{$card} {
        &__image {
          min-height: 190px;
          background-size: contain;
          border-bottom-left-radius: 0;
          border-top-right-radius: $border-radius;
        }

        &__content {
          border-top-width: 0;
          border-left-width: 1px;
          border-top-right-radius: 0;
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }

    &__social {
      &--facebook {
        width: 100%;
        height: 250px;
      }
    }
  }

  .pad {
    &-top {
      &-2 {
        padding-top: 0;
      }
    }

    &-bot {
      &-2 {
        padding-bottom: 1rem;
      }
    }
  }

  .mar {
    &-top {
      &-2 {
        padding-top: 1.5rem;
      }

      &-3 {
        padding-top: 1.5rem;
      }
    }
  }
}

.p-card-user {
  padding: 2.5rem;
}

.c-card-list {
  position: relative;
  font-family: Calibre, sans-serif;

  &__title {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    padding: 10px;
    color: #fff;
    cursor: normal;
  }

  &-heading--icon {
    position: relative;

    &.icon {
      top: 5px;
      left: 5px;
    }
  }

  &__item {
    font-family: Calibre, sans-serif;

    &.active {
      font-weight: bold;
      background-color: #eee;
    }

    &_author {
      max-width: 180px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  
    &_title {
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  
    &_order {
      width: 25px;
    }
  
    &_total {
      width: 50px;
    }
  
    &_avatar {
      max-height: 24px;
      width: 24px;
      height: 24px;
    }
  
    @media (min-width: 400px) {
      &_title {
        max-width: 290px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &_author {
        width: 180px;
      }
    }
  
    @media (min-width: 576px) {
      &_title {
        max-width: 300px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &_author {
        width: 180px;
      }
    }
  
    @media (min-width: 768px) {
      &_title {
        max-width: 330px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &_author {
        width: 180px;
      }
    }
  
    @media (min-width: 1024px) {
      &_title {
        max-width: 280px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &_author {
        width: 150px;
      }
    }
  
    @media (min-width: 1025px) {
      &_title {
        max-width: 420px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &_author {
        width: 180px;
      }
    }
  }


}