.c-table-of-content {
  $block: &;
  z-index: 1;
  max-width: 100%;
  width: auto;
  flex-direction: column;
  font-size: 0.9em;
  font-family: "Calibre", sans-serif;

  &__title {
    font-size: 1.2em;
    font-weight: bold;
    list-style-type: none;
    cursor: pointer;
    height: 40px;
    border-bottom: 1px solid $gray-200;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ol {
    padding: 0;
    margin: 0;
    padding-bottom: 40px;

    li {
      display: flex;
    }

    #{$block}__item {
      cursor: pointer;
      padding: 10px 0;
      max-height: 50px;
      text-overflow: ellipsis;
      border-bottom: 1px solid $gray-200;
      white-space: nowrap;
      transition: 0.3s;

      &:hover {
        padding-left: 5px;
      }
    }
  }

  @media (max-width: 1279.9px) {
    position: fixed;
    z-index: 2;
    max-height: auto;
    background: $white;
    padding-top: 4px;
    top: 78px !important;
    right: 0;
    width: 100%;
    padding: 0 24px;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    transition: right 0.4s;

    &__title {
      display: flex;
      align-items: center;
      border: 0;
    }
  }

  // Desktop and up
  @media (min-width: 1280px) {
    position: fixed;
    top: 156px;
    left: 30px;
    max-width: 200px;
    transition: 0.3s;

    &__title {
      .icon {
        display: none;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 320px) {
  .mobile-main-active {
    .c-table-of-content {
      right: calc(100% - 80px);
    }
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .mobile-main-active {
    .c-table-of-content {
      right: calc(100% - 135px);
    }
  }
}

@media (min-width: 376px) and (max-width: 575px) {
  .mobile-main-active {
    .c-table-of-content {
      right: calc(100% - 180px);
    }
  }
}
