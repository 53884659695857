@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&subset=latin-ext");

.c-message {
  width: 320px;
  height: 400px;
  box-shadow: 0 0 16px rgba($black, 0.3);
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  font-weight: normal;
  position: fixed;
  right: 30px;
  bottom: 0;
  background: $white;
  z-index: 12;

  @media (max-width: 767.98px) {
    width: 100%;
    right: 0;
  }

  form {
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  &.off {
    height: 40px;
  }

  &-header {
    color: $white;
    font-size: 0.9em;
    font-weight: normal;
    min-height: 40px;
    background: $primary;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
  }

  &-sub-header {
    height: 30px;
    background: $white;
    border-bottom: 1px solid $gray-200;
    padding: 5px 10px;
    font-size: 0.7em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $robins-egg-blue;

    span {
      cursor: pointer;
    }
  }

  &-body {
    height: 100%;
    padding: 10px;
    background: $white;
    display: flex;
    flex-direction: column;
  }

  &-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden; // !

    &__item {
      min-height: 40px;
      border-bottom: 1px solid $gray-300;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      font-size: 0.8em;
      cursor: pointer;
      transition: 0.3s;
      color: $gray-700;

      .date {
        font-size: 0.8em;
        color: $gray-600;
      }

      &:hover {
        background: $gray-200;
      }

      &.new {
        background: $peach-cream;
      }
    }

    .more-message {
      font-size: 0.7em;
      text-align: center;
      border: 1px solid $gray-300;
      color: $gray-600;
      max-width: 150px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      padding: 3px 10px;
      border-radius: 12px;
      cursor: pointer;
    }

    .c-message__item {
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      font-size: 0.8em;
      cursor: pointer;
      transition: 0.3s;
      color: $gray-700;
      overflow-y: scroll;

      .message {
        padding: 10px;
        font-size: 0.9em;
        margin-bottom: 35px;
        background: $gray-200;
        border-radius: 8px;
        width: 100%;

        .message-header {
          display: flex;
          justify-content: flex-start;
          position: relative;
          max-height: 0;
          top: -26px;
        }

        .name {
          padding: 3px 4px;
          border-radius: 4px;
          background: $gray-200;
          display: inline-flex;
          margin-bottom: 3px;
          height: 25px;
        }

        .date {
          margin-left: auto;
          padding-bottom: 4px;
          color: $gray-600;
          font-size: 0.7em;
        }
      }

      .message.me {
        background: $peach-cream;
        border-radius: 8px;

        .message-header {
          display: flex;
          justify-content: flex-end;

          .name {
            background: $peach-cream;
          }

          .date {
            margin-left: 0;
            margin-right: auto;
          }
        }

        .content {
          border-radius: 16px;
        }
      }
    }
  }

  &-input {
    display: flex;
    border-top: 1px solid $gray-300;
    position: relative;
    z-index: 1;
    align-items: center;
    height: 80px;

    textarea {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      font-size: 0.8em;
      border: 0;
      outline: none;
      padding: 10px 20px;
    }
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
