.badge {
  font-family: $headings-font-family;
  color: $white;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge-science {
  color: $white;
}

.badge-tag {
  background: $gray-500;
}
