$version: random(42000);
$font-name: icons;
$font-file-name: "icons";
$font-folder: "fonts/icons";
$font-weight: 400;
$font-style: normal;

// scss-lint:disable all
$icons: ("arrow-back": "E001", "arrow-bottom": "E002", "arrow-next": "E003", "bold": "E004", "check": "E005", "clap": "E006", "clock": "E007", "edit": "E008", "ellipsis-v": "E009", "email": "E00A", "eyes": "E00B", "facebook-borderless": "E00C", "facebook": "E00D", "follow": "E00E", "following": "E00F", "full-view": "E010", "google": "E011", "heading": "E012", "heart-line": "E013", "heart": "E014", "image-delete": "E015", "italic": "E016", "king": "E017", "large-view": "E018", "lighting": "E019", "link": "E01A", "list-circle": "E01B", "list-number": "E01C", "magnify": "E01D", "media": "E01E", "normal-view": "E01F", "picture": "E020", "plus": "E021", "reply": "E022", "rotate-left": "E023", "rotate-right": "E024", "sm-facebook": "E025", "sm-google": "E026", "sm-instagram": "E027", "sm-linkedin": "E028", "sm-twitter": "E029", "seperator": "E02A", "share": "E02B", "slider-view": "E02C", "twitter-borderless": "E02D", "twitter": "E02E", "underline": "E02F", "unlink": "E030", "warning": "E031", "close": "E032", "mail": "E033", "phone": "E034");
// scss-lint:enable all

@font-face {
  font-family: #{$font-name};
  font-weight: #{$font-weight};
  font-style: #{$font-style};
  src: url("#{$font-folder}/#{$font-file-name}.woff2?v=#{$version}")
      format("woff2"),
    url("#{$font-folder}/#{$font-file-name}.woff?v=#{$version}") format("woff"),
    url("#{$font-folder}/#{$font-file-name}.ttf?v=#{$version}")
      format("truetype");
}

.icon {
  display: inline-block;
  font: normal normal normal 14px / 1 #{$font-name};
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @each $filename, $codepoint in $icons {
    $code: "\"\\#{$codepoint}\"";

    &-#{$filename}::before {
      content: unquote($code);
    }
  }
}

// scss-lint:disable FinalNewline