@include font-face("Calibre", "calibre", "Calibre-Regular");
@include font-face("Calibre", "calibre", "Calibre-Medium", 500);
@include font-face("Carrara", "carrara", "Carrara-Regular");

$font-family-sans-serif: "Carrara", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$headings-font-family: "Calibre", sans-serif;

$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;

$font-size-base: 1.125rem !default;

$font-sizes: (xs: "- 4px", sm: "- 2px", md: "+ 2px", lg: "+ 4px", xl: "+ 8px");

$line-height-sm: 1 !default;

$badge-font-weight: $font-weight-normal;
