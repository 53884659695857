.c-mobile {
  $block: &;

  top: 0;
  left: 100%;
  width: 240px;
  height: 100vh;
  z-index: 10;
  position: absolute;
  transition: left 0.4s;

  &--active {
    left: calc(100% - 240px);

    #{$block}__button {
      i:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      i:nth-child(2) {
        transform: translate(-150%, -50%);
        opacity: 0;
      }

      i:nth-child(3) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      i:nth-child(1),
      i:nth-child(3) {
        margin: 0;
        background-color: $body-bg;
      }
    }

    #{$block}__backdrop {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__button {
    top: 17px;
    left: -50px;
    width: 40px;
    border: 0;
    height: 40px;
    position: absolute;
    appearance: none;
    background: none;

    i {
      top: 50%;
      left: 50%;
      width: 30px;
      height: 3px;
      position: absolute;
      transform: translate(-50%, -50%);
      background: $primary;
      transform-origin: center;
      transition: transform 0.4s, margin 0.4s, opacity 0.4s, color 0.4s;

      &:nth-child(1) {
        margin-top: -8px;
      }

      &:nth-child(3) {
        margin-top: 8px;
      }
    }
  }

  &__backdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    position: fixed;
    background: rgba($black, 0.4);
    transition: opacity 0.4s;
    pointer-events: none;
  }

  &__nav {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: $body-bg;
  }

  &__item {
    color: $primary;
    display: block;
    padding: 5px 15px;
    font-size: 22px;
    font-weight: $headings-font-weight;
    font-family: $headings-font-family;
  }
}
