.c-carousel {
  position: relative;
  margin-top: 250px;
  margin-bottom: 5em;

  &-item {
    &-s1 {
      width: 85px;
    }

    &-s2 {
      width: 135px;
    }
  }

  &::before {
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &__arrow {
    color: $body-color;
    font-size: 1em;

    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 0;
    text-align: center;
    transition: color 0.4s;
    opacity: 1;

    &::before {
      content: none;
    }

    &:hover,
    &:focus {
      color: $primary;
      opacity: 1;
    }
  }
}

@include media-breakpoint-down(xs) {
  .c-carousel {
    margin-top: 100px;

    &__arrow {
      width: 30px;
      height: 30px;
    }

    &-item {
      &-s1 {
        width: 74px;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .c-carousel {
    &-item {
      &-s1 {
        width: 73px;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 1024px) {
  .slick-slide {
    padding: 0;
  }
}
