button {
  background-color: transparent;
}

.btn {
  z-index: 0;
  font-family: $headings-font-family;

  &-primary {
    color: $white;
  }

  &-block {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &-add {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 0;
    background: $primary;
    color: $white;
    transition: $transition-base;
    position: relative;
    outline: none;
    padding: 0;

    &::before,
    &::after {
      content: "";

      background: currentColor;

      width: 0.75rem;
      height: 2px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &.active {
      transform: rotate(45deg);
    }
  }

  &-editor {
    @include button-outline-variant($link-color, $primary);
    border-radius: 17px;
    padding: 0.1rem 1rem;
    font-weight: $font-weight-normal;

    &:not(:disabled):not(.disabled) {
      &:hover,
      &:active,
      &:focus {
        border-color: $primary;
        color: $primary;
        background: transparent;
        outline: none;
        box-shadow: none;
      }
    }
  }

  &:not([disabled]):not(.disabled) {
    &:active,
    &.active,
    &:focus,
    &.focus {
      box-shadow: none;
    }
  }

  &-outline-primary:not([disabled]):not(.disabled) {
    color: $custom-checkbox-color;
    transition: color 0.15s;
    border-color: $border-color;

    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $custom-button-primary-hover;
      background: transparent;
      box-shadow: none;
      border-color: $primary;
    }
  }

  &-outline-secondary:not([disabled]):not(.disabled) {
    border-color: $border-color;
    color: $custom-checkbox-color;
    transition: color 0.15s;

    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $custom-button-primary-hover;
      background: transparent;
      box-shadow: none;
      border-color: $primary;
    }
  }

  &-link {
    &,
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}

.form-check {
  $check: &;

  &-label {
    display: flex;
    padding-left: 1.5rem;
  }

  &-input {
    appearance: none;

    background: $body-bg;
    color: $border-color;
    border: 1px solid currentColor;

    font-size: 1.5rem;
    margin-left: -1em;
    margin-top: 0;

    width: 1em;
    height: 1em;

    border-radius: $border-radius;
    outline: none;

    position: relative;

    &:not(:disabled):not(.disabled) {
      &::before {
        content: "";

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        margin-top: -2px;
        font-size: 0.9rem;

        width: 1em;
        height: 0.5em;
        border: 2px solid currentColor;
        border-width: 0 0 2px 2px;
        color: inherit;

        opacity: 0;
      }

      &:hover {
        color: $hit-gray;
      }

      &:checked {
        color: $body-bg;
        background: theme-color("primary");
        border-color: transparent;
      }

      &:hover::before,
      &:checked::before {
        opacity: 1;
      }
    }
  }
}

.form-control {
  padding: 0.6567rem 1rem;

  &:hover {
    border-color: $input-hover-border-color;
    box-shadow: none;
  }

  &:focus {
    border-color: $primary;
    box-shadow: none;
  }

  &:disabled {
    border-color: $border-color;
  }
}

.error-border {
  border: 1.4px solid $mandy;
}

.font-size-10 {
  font-size: 15px;
  color: $mandy;
}
