.Select--multi .Select-value {
  color: $white;
  margin: 5px;
  background: $primary;

  &-icon {
    padding: 5px 5px 3px;
  }
}

.Select-control {
  border-color: $primary;
}
