.c-header {
  width: 100%;
  display: block;
  border-bottom: 1px solid $border-color;
  min-height: 77px;

  &--menu {
    min-width: 400px;
  }

  @include media-breakpoint-down(sm) {
    &--menu {
      text-align: center;
      min-width: 0;
    }
  }
}

.confirmation {
  color: $white;
  height: 62px;
  display: flex;
  background: $primary;

  &-area {
    display: flex;
    padding: 10px 0 15px;
    align-items: center;

    span {
      font-size: 1rem;
    }
  }
}

.display {
  &-ipad {
    display: inline-block;

    &-menu {
      display: none;
    }
  }
}

.white-space {
  white-space: normal;
  overflow-x: hidden;
}

@media (min-width: 450px) and (max-width: 768px) {
  .pp-m-0 {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }

  .display {
    &-ipad {
      display: none;

      &-menu {
        display: block;
      }
    }
  }

  .mar-right-05 {
    margin-right: 0.5rem !important;
  }

  .iPad {
    padding: 0.375rem 0.9rem !important;
  }

  .iPadNew {
    padding: 0.375rem 0.7rem !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .display {
    &-ipad {
      display: inline-block;

      &-menu {
        display: none;
      }
    }
  }

  .mar-right-05 {
    margin-right: 0.5rem !important;
  }

  .iPadNew {
    padding: 0.375rem 0.7rem !important;
  }
}

.p-fixed {
  position: fixed !important;
}

.z {
  &-9 {
    z-index: 1039;
  }

  &-2 {
    z-index: 2;
  }

  &-1 {
    z-index: 1;
  }
}

.mt-6rem {
  margin-top: 4.8rem;
}

.mt-3rem {
  margin-top: 3.8rem;
}
