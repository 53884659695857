// .c-toolbox {
//   position: absolute;
//   transform: translateY(-50%);
//   pointer-events: none;

//   .btn-add {
//     position: absolute;
//     left: -10px;
//     top: 50%;
//     transform: translate(-100%, -50%);
//     transition: transform 0.3s;
//     pointer-events: all;

//     &.active {
//       transform: translate(-100%, -50%) rotate(45deg);
//     }
//   }

//   &.active {
//     pointer-events: all;
//   }
// }

$container-width: map-get(
  $map: $container-max-widths,
  $key: xl
);
$width: $container-width / $grid-columns * 8;

.c-toolbox {
  position: absolute;
  width: $width;
  left: calc((100vw - #{$width}) / 2);
  top: 0;
  padding: 0 $grid-gutter-width / 2;

  &__toggle {
    position: absolute;
    appearance: none;
    background: $primary;
    transition: $transition-base;

    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 0;
    border: 0;
    margin-left: -42px;

    &,
    &:focus,
    &:active,
    &:hover {
      outline: none;
    }

    &::before,
    &::after {
      content: "";
      width: 12px;
      height: 2px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      background: $white;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &--active {
      transform: rotate(45deg);
    }
  }

  &__button {
    color: $link-color;
    background: $white;
    border: 1px solid currentColor;
    font-size: 16px;
    border-radius: 17px;
    padding: 0 1rem;
    height: 32px;
    font-family: $headings-font-family;

    &:hover {
      color: $primary;
    }

    .icon {
      margin-right: $spacer * 0.5;
    }

    .icon,
    span {
      vertical-align: middle;
    }
  }
}

@include media-breakpoint-down(sm) {
  .c-toolbox {
    &__toggle {
      margin-left: 0;
      margin-top: -40px;
    }

    &__button {
      padding: 0 0.7rem;
    }
  }
}
