$btn-font-weight: $font-weight-bold !default;
$btn-link-disabled-color: $iron !default;

$btn-border-radius: 0.125rem !default;
$btn-border-radius-lg: 0.125rem !default;
$btn-border-radius-sm: 0.125rem !default;

$input-btn-padding-x: 1.6rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-padding-x-lg: $input-btn-padding-x !default;

$input-btn-padding-y-lg: 0.563rem !default;

$input-border-color: $border-color;
