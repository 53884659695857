.c-tooltip {
  position: absolute;
  transform: translate(-50%, -100%);
  font-size: 0;
  z-index: 1;

  &::after {
    content: "";
    border: 5px solid transparent;
    border-top-color: $primary;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }

  .btn-primary {
    font-size: 12px;
    padding: 10px 15px;
  }
}

.c-editor__image {
  z-index: 1;

  .c-tooltip {
    .btn-primary {
      font-size: 16px;
    }
  }
}

.tooltip {
  padding: 0;

  &-inner {
    margin: 0;
  }

  &-link {
    padding: 0.6rem 3rem 0.5rem 1rem;
  }
}

.bs-tooltip-top {
  .arrow {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
