.t-right {
  text-align: right;
}

.comment_board textarea {
  border: 0;
  padding: 0;
  box-shadow: none;
}

.c-follow-btn {
  display: none;

  @media (min-width: 1199.98px) {
    position: fixed;
    top: 260px;
    right: 96px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}

.c-edit-btn {
  // display: none;

  @media (min-width: 1199.98px) {
    position: fixed;
    top: 330px;
    right: 96px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}

.c-report-btn {
  @media (min-width: 1199.98px) {
    position: fixed;
    top: 468px;
    right: 96px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}

.c-report-textarea {
  resize: none;
  border: 1px solid #eee;
  height: 120px;
}

.c-bookmark-btn {
  @media (min-width: 1199.98px) {
    position: fixed;
    top: 535px;
    right: 96px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}

.c-shared {
  outline: 0;

  @media (min-width: 1199.98px) {
    position: fixed;
    top: 400px;
    right: 96px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}

.c-edit-btn__text,
.c-shared__text {
  font-size: 12px;
  padding: 8px 13px;
  height: 25px;
  color: $white;
  background-color: $gray-900;
  font-family: "Calibre", sans-serif;
  border-radius: 3px;
  z-index: 999;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: -20px;
  right: 25%;

  @media (min-width: 1199.98px) {
    right: -30%;
  }
}

.c-bookmark-btn__button,
.c-report-btn__button,
.c-follow-btn__button,
.c-edit-btn__button,
.c-shared__button {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid $primary;
  color: $shuttle-gray;
  border-radius: 50%;
  cursor: pointer;
  background: $white;
  font-size: 1.3em;

  .icon {
    margin-right: 4px;
  }

  &:active {
    animation-name: coom;
    animation-duration: 0.2s;

    i {
      color: $primary;
    }
  }
}

.c-bookmark-btn__button {
  .icon {
    margin-right: 0px;
  }
  &.active {
    .icon {
      color: $primary;
    }
  }
}

.c-report-btn__button {
  .icon {
    margin-right: -2px;
  }

  &.active {
    color: $primary;
  }
}

.c-edit-btn__button,
.c-follow-btn__button {
  .icon {
    margin-right: -5px;
  }

  &.active {
    color: $primary;
  }
}

@include media-breakpoint-down(sm) {
  .t-right {
    text-align: initial;

    a {
      margin-right: 5px;
    }
  }

  .p-t-15 {
    padding-top: 15px;
  }

  .text__center {
    text-align: center;
  }
}
