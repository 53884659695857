@keyframes spinner {
  0% {
    transform: scale(1);
  }

  33% {
    transform: scale(.8);
  }

  66% {
    transform: scale(1.2);
  }
}

.c-spinner {
  position: absolute;
  font-size: 20px;
  left: 50%;
  top: 50%;
  z-index: 1051;
  width: 5em;
  height: 1em;
  transform: translateX(-50%);

  span {
    width: 1em;
    height: 1em;
    background: $white;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    animation: spinner infinite 1s normal linear;

    &:nth-child(1) {
      left: 0;
      animation-delay: -.3s;
    }

    &:nth-child(2) {
      left: 50%;
      margin-left: -.5em;
    }

    &:nth-child(3) {
      right: 0;
      animation-delay: .3s;
    }
  }
}
