.c-footer {
  border-top: 1px solid $border-color;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  &__logo {
    img {
      max-height: 30px;
    }
  }

  &-social {
    text-align: right;

    i {
      padding-left: 1rem;
      font-size: 1.225rem;

      &:hover {
        color: $primary;
      }
    }

    &-media {
      &-justify {
        justify-content: flex-start;
      }
    }
  }
}

.mobile {
  display: none;
}

@include media-breakpoint-down(sm) {
  .c-footer {
    position: relative;

    &-social-media {
      &-justify {
        justify-content: space-between;
      }

      &-iphone-x {
        text-align: center;
        position: absolute;
        bottom: 115px;
        padding-top: 10px;
        border-top: 1px solid $primary;

        i {
          padding-left: 0;
          padding-right: 1rem;
        }
      }

      &-plus {
        text-align: center;
        position: absolute;
        bottom: 75px;
        padding-top: 10px;
        border-top: 1px solid $primary;

        i {
          padding-left: 0;
          padding-right: 1rem;
        }
      }

      &-6-7-8 {
        text-align: center;
        position: absolute;
        bottom: 75px;
        padding-top: 10px;
        border-top: 1px solid $primary;

        i {
          padding-left: 0;
          padding-right: 1rem;
        }
      }

      &-se-5s {
        text-align: center;
        position: absolute;
        bottom: 75px;
        padding-top: 10px;
        border-top: 1px solid $primary;

        i {
          padding-left: 0;
          padding-right: 1rem;
        }
      }

      &-android {
        text-align: center;
        position: absolute;
        bottom: 0;
        padding-top: 10px;
        border-top: 1px solid $primary;

        i {
          padding-left: 0;
          padding-right: 1rem;
        }
      }
    }
  }

  .mobile {
    display: block;
  }

  .pym-3 {
    padding-bottom: 0;
  }
}
