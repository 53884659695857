$container-width: map-get(
  $map: $container-max-widths,
  $key: xl
);
$width: $container-width / $grid-columns * 8;

.c-editor {
  font-size: 20px;
  line-height: 32px;
  position: relative;

  > li {
    max-width: 100%;
  }

  > li,
  &__item {
    margin-left: auto;
    margin-right: auto;
    width: $width;
    padding: 0 $grid-gutter-width / 2;
    position: relative;
  }

  &__item {
    margin-bottom: 30px;
  }

  &__item[select="selected"] hr {
    box-shadow: 0 0 5px $primary;
  }

  &__title + &__paragraph {
    font-size: 24px;
  }

  &__paragraph {
    font-size: 20px;
  }

  &__image {
    text-align: center;

    &--wide {
      width: $container-width / $grid-columns * 10 - $grid-gutter-width;
      padding: 0;
    }

    &--full {
      width: 100%;
      padding: 0;
    }
  }

  &__seperator {
    width: 100%;
  }

  &__placeholder {
    position: relative;

    &:empty::before,
    &--show::before {
      content: attr(placeholder);
      position: absolute;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__list {
    padding-left: calc(1em + 13px);
  }
}

.avatarImage {
  width: 300px;
  height: 300px;
}

.embed {
  &-facebook {
    max-width: 560px;
    left: 50% !important;
    transform: translateX(-50%);
  }
}

@include media-breakpoint-down(sm) {
  .c-editor {
    &__item {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(xs) {
  .c-editor {
    &__item {
      margin-bottom: 15px;
    }

    &__title + &__paragraph {
      font-size: 22px;
    }
  }
}
