.c-custom-checkbox {
  appearance: none;
  width: 50px;
  height: 30px;
  border-radius: 20px;
  background: $secondary;
  position: relative;
  transition: background-color 0.4s;
  outline: none;
  display: inline-block;

  &::after {
    content: "";
    width: 20px;
    height: 20px;
    background: $white;
    border-radius: 50%;

    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);

    transition: left 0.4s;
  }

  &:checked {
    background: $primary;

    &::after {
      left: calc(100% - 25px);
    }
  }
}
