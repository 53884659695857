.c-media {
  border: 1px solid $gray-300;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 1.5rem;

  @media (max-width: 500px) {
    flex-direction: column;
  }

  .avatar {
    margin-right: 30px;
    min-width: 55px;
    min-height: 55px;
    max-width: 55px;
    max-height: 55px;

    @media (max-width: 500px) {
      padding-right: 0;
      margin: 0;
    }

    img {
      border-radius: 50%;
    }
  }

  .user {
    width: 100%;
    padding-right: 75px;

    .username {
      align-items: start;
    }

    @media (max-width: 500px) {
      width: inherit;
      padding-right: 0;

      .username {
        align-items: center;
      }
    }

    p {
      font-size: 1rem;
    }
  }

  .comment {
    text-align: left;

    @media (max-width: 500px) {
      width: inherit;
      padding-right: 0;
    }

    p {
      font-size: 1rem;
    }
  }
}
