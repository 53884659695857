.c-search {
  @include media-breakpoint-down(sm) {
    position: absolute;
    left: 0;
    z-index: 1;
  }

  input {
    font-family: $headings-font-family;
    width: 0;
    transition: width 0.4s, padding 0.4s, opacity 0.4s;
    padding: 0;
    opacity: 0;
  }

  &.active {
    input {
      width: 200px;
      padding: inherit;
      opacity: 1;
      padding-left: 10px;
    }
  }

  @media (min-width: 450px) and (max-width: 1024px) {
    &.active {
      input {
        width: 105px;
      }
    }
  }

  &-center {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 48vh;
  }
}

.search-btn {
  padding: 10px 10px 5px;
  background: transparent;
  border: 1px solid $chatelle;
}
