body {
  overflow-x: hidden;
  overflow-y: scroll;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

time {
  font-family: $headings-font-family;
  line-height: 1.4;
}
