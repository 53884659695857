.c-category-nav {
  position: relative;

  &__item {
    margin: 0;
    display: inline-block;
    position: relative;
    color: $text-muted;
    text-align: center;
    transition: color 0.4s;

    &::after {
      content: "";

      position: absolute;
      left: 0;
      bottom: 0;

      width: 0;
      height: 3px;
      background: theme-color("primary");

      transition: width 0.4s;

      z-index: 1;
    }

    &:hover,
    &.active {
      text-decoration: none;
      color: $headings-color;
    }

    &:hover::after,
    &.active::after {
      width: 100%;
    }
  }

  &__dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    width: 50%;
    background: $white;
    z-index: 1;
    padding: 0 15px 0 30px;
    column-count: 4;
    margin-top: 6px;
    box-shadow: 0 0 3px rgba($black, 0.3);
  }

  &::after {
    content: "";
    height: 1px;
    background: $border-color;
    position: absolute;
    left: 0;
    bottom: 1px;
    right: 0;
  }
}
