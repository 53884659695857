$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1176px) !default;
$badge-border-radius: 0.125rem !default;
$badge-padding-y: 0.408em !default;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.5,
    2: $spacer * 1,
    3: $spacer * 1.5,
    4: $spacer * 2,
    5: $spacer * 2.5,
    6: $spacer * 3
  )
  !default;

$dropdown-link-color: $abbey !default;
$dropdown-link-hover-color: $primary !default;
$dropdown-link-hover-bg: $white !default;

$dropdown-link-active-color: $primary !default;
$dropdown-link-active-bg: $white !default;

$font-size-lg: 1.25rem !default;
