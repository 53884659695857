.y-mobile {
  $block: &;

  top: 0;
  left: 100%;
  position: absolute;
  transition: left 0.4s;

  &-navigation {
    width: 250px;
    height: 100vh;
    top: 0;
    right: -100%;
    bottom: 0;
    background: $white;
    position: fixed;
    transition: right 0.4s;
    padding-top: 10px;
    padding-bottom: 10px;

    &__item {
      padding: 0.5rem;
      font-size: 1.125rem;

      i {
        left: auto;
        right: 25px;
        position: absolute;
        margin-top: 5px;
      }
    }

    &__title {
      padding-bottom: 1rem;
    }

    &.active {
      right: 0;
      z-index: 5;
      overflow: scroll;

      .y-mobile__button i:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      .y-mobile__button i:nth-child(2) {
        transform: translate(-150%, -50%);
        opacity: 0;
      }

      .y-mobile__button i:nth-child(3) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      .y-mobile__button i:nth-child(1),
      .y-mobile__button i:nth-child(3) {
        margin: 0;
        background-color: $body-bg;
      }
    }
  }

  &__backdrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
    position: fixed;
    background: $black;
    transition: opacity 0.4s;
    pointer-events: none;

    &-active {
      opacity: 0.5;
      z-index: 7;
      pointer-events: all;
    }
  }

  &__nav {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: $body-bg;
  }

  &-body ul li,
  &-footer ul li {
    list-style: none outside none;
    padding-left: 0;
  }

  &__button {
    top: 20px;
    left: -63px;
    width: 40px;
    border: 0;
    height: 40px;
    z-index: 10;
    position: absolute;
    background: none;

    &.active {
      left: 20px;
      position: fixed;
      transition: right 0.4s;

      @media (min-width: 321px) and (max-width: 376px) {
        left: 70px;
      }

      @media (min-width: 377px) and (max-width: 426px) {
        left: 110px;
      }

      i:nth-child(1) {
        margin: 0;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: $white;
      }

      i:nth-child(2) {
        opacity: 0;
        transform: translate(-150%, -50%);
      }

      i:nth-child(3) {
        margin: 0;
        transform: translate(-50%, -50%) rotate(-45deg);
        background-color: $white;
      }
    }

    i {
      top: 50%;
      left: 50%;
      width: 30px;
      height: 3px;
      position: absolute;
      transform: translate(-50%, -50%);
      background: $primary;
      transform-origin: center;
      transition: transform 0.4s, margin 0.4s, opacity 0.4s, color 0.4s;

      &:nth-child(1) {
        margin-top: -8px;
      }

      &:nth-child(3) {
        margin-top: 8px;
      }
    }

    @include media-breakpoint-down(sm) {
      i {
        width: 40px;
        height: 4px;
      }
    }
  }
}

.hh-0 {
  height: 0 !important;
  opacity: 0;
  transition: all 0.3s ease;
}

.hh-100 {
  opacity: 1;
  transition: all 0.4s ease;
}

.b-initial {
  &-iphone-x {
    height: 20%;
    bottom: initial !important;
  }

  &-plus {
    height: 15%;
    bottom: initial !important;
  }

  &-6-7-8 {
    height: 18%;
    bottom: initial !important;
  }

  &-se-5s {
    height: 25%;
    bottom: initial !important;
  }

  &-android {
    bottom: initial !important;
  }
}

.mobile-menu + .mobile-menu {
  border-top: 1px solid $primary;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 0px) and (max-width: 320px) {
  .mobile-main {
    right: 0;
    transition: right 0.4s;

    &-active {
      right: calc(100% - 80px);
    }
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .mobile-main {
    right: 0;
    transition: right 0.4s;

    &-active {
      right: calc(100% - 135px);
    }
  }
}

@media (min-width: 376px) and (max-width: 575px) {
  .mobile-main {
    right: 0;
    transition: right 0.4s;

    &-active {
      right: calc(100% - 180px);
    }
  }
}
