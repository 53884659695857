.c-clap {
  font-family: Arial;
  position: fixed;
  border-radius: 50%;

  @media (min-width: 1200px) {
    position: fixed;
    top: 190px;
    right: 96px;
    z-index: 1;
  }

  @media (max-width: 1199.98px) {
    position: relative;
    width: 100px;
  }
}

.c-clap-count {
  margin-bottom: 6px;
  font-size: 0.9em;
  color: $gray-700;
  min-height: 30px;

  @media (max-width: 1200px) {
    min-height: 0;
    margin-right: 10px;
  }
}

.c-clap-add {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid $primary;
  border-radius: 50%;
  cursor: pointer;
  background: $white;
  font-size: 1.7em;
  z-index: 1;

  &.active {
    i {
      color: $primary;
    }
  }
}

.c-clap-add:active {
  animation-name: coom;
  animation-duration: 0.2s;

  i {
    color: $primary;
  }
}

*[data-name="mojs-shape"] {
  pointer-events: none;
}

.c-clap-remove {
  width: 40px;
  height: 26px;
  padding: 10px;
  padding-left: 6px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  background: $gray-200;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  position: absolute;
  top: 15px;
  left: 15px;
  margin-left: 0;
  z-index: 1;
  cursor: pointer;
  font-size: 0.6em;

  @media (max-width: 1199.98px) {
    top: 18px;
    left: 15px !important;
  }

  &:active {
    box-shadow: 0px 0px 3px 3px $gray-400;
  }

  .icon {
    width: 10px;
  }
}

.c-clap:hover .c-clap-remove {
  animation-name: anim;
  animation-duration: 10s;
}

.c-clap:active .c-clap-count {
  color: $gray-500;
  font-size: 1.1em;
}

@keyframes coom {
  0% {
    box-shadow: 0 0 5px 1px rgba($primary, 0.4);
  }

  100% {
    box-shadow: 0 0 15px 1px rgba($primary, 0.8);
  }
}

@keyframes anim {
  0% {
    margin-left: 0;
  }

  10% {
    margin-left: 0;
  }

  11% {
    margin-left: 26px;
  }

  100% {
    margin-left: 26px;
  }
}

/*========================
    SASS definitions
  =======================*/
$btn-dimension: 55px;
$primary-color-clap: rgba(189, 195, 199, 1);
$secondary-color-clap: $primary;
@mixin debug {
  outline: 1px solid red;
}

/*========================
    BUTTON styles
  =======================*/
.clap {
  background: white;
  position: relative;
  display: flex;
  color: #5a6066;
  font-size: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial;
  position: relative;
  outline: 1px solid transparent !important;
  border-radius: 50%;
  border: 1px solid $primary;
  width: $btn-dimension;
  height: $btn-dimension;
  z-index: 2;

  &.active {
    color: $primary;
  }

  span.icon-wrap {
    display: flex;

    .icon {
      padding-left: 2px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50%;
    //border: 1px solid $primary-color-clap;
    width: $btn-dimension - 1px;
    height: $btn-dimension - 1px;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid $secondary-color-clap;
    transition: border-color 0.3s ease-in;
    &:after {
      animation: shockwave 1s ease-in infinite;
    }
  }
  svg {
    width: 40px;
    fill: none;
    stroke: $secondary-color-clap;
    stroke-width: 2px;
    &.checked {
      fill: $secondary-color-clap;
      stroke: #fff;
      stroke-width: 1px;
    }
  }
  .clap--count {
    position: absolute;
    top: -$btn-dimension/1.6;
    left: $btn-dimension/4;
    font-size: 0.8rem;
    color: white;
    text-align: center;
    background: $secondary-color-clap;
    border-radius: 50%;
    height: $btn-dimension/2;
    width: $btn-dimension/2;
    line-height: $btn-dimension/2;
  }
  .clap--count-total {
    position: absolute;
    font-size: 15px;
    color: #5a6066;
    width: $btn-dimension;
    text-align: center;
    left: 0;
    top: -27px;
  }
}

/*====================
        Message
======================*/
#message {
  position: absolute;
  bottom: 20px;
  color: $secondary-color-clap;
  line-height: 1.52rem;
  padding: 1rem;
  font-size: 0.9rem;
  a {
    color: $primary-color-clap;
  }
}

@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px $secondary-color-clap;
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
    box-shadow: 0 0 50px darken($secondary-color-clap, 20%),
      inset 0 0 10px $secondary-color-clap;
  }
}
