$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$jungle-green: #c41649 !default;
$green-haze: #c41649 !default;
$alabaster: #fafafa !default;

$mako: #43474c !default;
$shuttle-gray: #5a6066 !default;
$cape-cod: #3b4446 !default;
$abbey: #525e61 !default;
$rolling-stone: #7b868a !default;
$hit-gray: #aeb6b8 !default;
$iron: #d7dcdd !default;
$porcelain: #eff2f3 !default;
$havelock-blue: #4a90e2 !default;
$monza: #d0021b !default;
$black-haze: #f7f8f8 !default;
$alice-blue: #f0f8ff !default;
$cornflower-blue: #51a0fa !default;
$robins-egg-blue: #00bcd4 !default;

$mandy: #e44e4e !default;
$jaffa: #f1743f !default;
$sea-buckthorn: #f4a62d !default;
$conifer: #b4e84c !default;
$emerald: #37d551 !default;
$shamrock: #38cbb1 !default;
$picton-blue: #3ea1e6 !default;
$mariner: #2553dd !default;
$royal-blue: #774cf3 !default;
$lavender-magenta: #ee5dd2 !default;
$cerise-red: #e02973 !default;
$catskill-white: #f4f7fa !default;
$chatelle: #c3bdc7;
$peach-cream: #fff1dd;

$facebook: #3b5998 !default;
$twitter: #1da1f2 !default;
$google: #dd4b39 !default;

$colors: (
  inherit: inherit,
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
  catskill-white: $catskill-white
) !default;

$primary: $jungle-green;
$secondary: $iron;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $catskill-white;
$dark: $rolling-stone;
$science: $sea-buckthorn;
$health: $picton-blue;
$music: $royal-blue;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  science: $science,
  health: $health,
  music: $music,
  facebook: $facebook,
  twitter: $twitter,
  google: $google,
  tag: $porcelain
) !default;

$body-color: $shuttle-gray !default;
$link-color: $rolling-stone !default;
$headings-color: $cape-cod !default;
$border-color: $iron !default;
$hr-border-color: $border-color !default;
$text-muted: $rolling-stone !default;
$input-hover-border-color: $hit-gray !default;
$input-disabled-bg: $light !default;

$custom-checkbox-bg: $porcelain !default;
$custom-checkbox-color: $abbey !default;

$custom-button-primary-hover: $green-haze !default;
